<template>
  <div>
    <p class="text-2xl mb-6">Relacion Grupo Negocio</p>
   
    <v-col cols="12">
      <ReporteRelacionCajaGrupo  :filtrosShow="true" :exportar="['EXCEL']"></ReporteRelacionCajaGrupo>
    </v-col>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

// demos 
import ReporteRelacionCajaGrupo from './componentes/ReporteRelacionCajaGrupo.vue'

import DosFechas from '@/components/DosFechas.vue'
import SelectGrupoNegocio from '@/components/SelectGrupoNegocio.vue'
import funciones from '@/funciones/funciones'
import moment from 'moment'
export default {
  components: { 
    ReporteRelacionCajaGrupo, 
    DosFechas,
    SelectGrupoNegocio,
  },
  setup() {
    const filtros = ref({
      fecha: {
        desde: moment().format('YYYY-MM-DD'),
        hasta: moment().format('YYYY-MM-DD'),
      },
      id_grupo_negocio: 1,
      grupo_negocio: { descripcion: '2' },
    })
    const getFechas = fecha => {
      filtros.value.fecha.desde = fecha.desde
      filtros.value.fecha.hasta = fecha.hasta
       
    }

    const getGrupoNegocio = dato => { 
      filtros.value.id_grupo_negocio = dato.id
      filtros.value.grupo_negocio = dato 
    }


    return {
      getFechas,
      getGrupoNegocio,
      funciones,
    }
  },
}
</script>
